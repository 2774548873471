import React, { useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { debounce } from '@utils/debounce'

// Components
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/pages/client-testimonials/Banner'
import ReviewsTrustedByTheBest from '@components/global/Reviews/TrustedByTheBest'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'
import ReviewsFiveStarWithSlider from '@components/global/Reviews/FiveStarWithSlider'
import ReviewsClientTestimonials from '@components/global/Reviews/ClientTestimonials'
import Faqs from '@components/global/accordions/Faqs'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'
import HomeReviews from '@components/pages/home/Reviews'

// CSS
import * as cx from './ClientTestimonials.module.scss'

// Image
import FaqsBackground from '/content/assets/form/LCF06/group-4.jpg'

const ClientTestimonialsTemplate = ({
  data: { page, bannerDevice },
  pageContext: { seo, uri },
}) => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    const detectScrollPositionY = debounce(() => {
      setScroll(window.scrollY > 80)
    }, 50)

    detectScrollPositionY()

    window.addEventListener('scroll', detectScrollPositionY)

    return () => {
      window.removeEventListener('scroll', detectScrollPositionY)
    }
  })

  const faqsData = [
    {
      question: 'Agent Image Website Design',
      answer: [
        'Agent Image offers user-friendly websites designed for everyone, whether they are new real estate agents, established real estate teams, or top-producing luxury real estate brokers. Whether you want a theme-based, semi-custom, or fully bespoke site, Agent Image can create real estate websites that drive lead generation and build your brand.',
        'We are proud to have designed some of the best real estate websites for some of the largest real estate groups in the country.',
      ],
    },
    {
      question: 'Social Media Packages',
      answer: [
        "Agent Image offers social media management, including content creation for Facebook, Twitter, and Instagram. Whether as part of your website design package or as an a-la-carte service, social media management can help you harness today's most popular platforms to build your online presence.",
      ],
    },
    {
      question: 'Blogging Packages',
      answer: [
        "Agent Image recommends that every real estate agent have a blog section on their website to showcase their expertise. If writing isn't your forte, our talented team of copywriters can create SEO-friendly, professionally written blogs for you.",
      ],
    },
    {
      question: 'SEO for Realtors Package',
      answer: [
        'Agent Image offers three levels of real estate SEO packages designed to move your website up in the rankings. Make SEO work for you!',
      ],
    },
    {
      question: 'Other Agent Image Services',
      answer: [
        'Check out our Real Estate Internet Marketing page to see how we can help you with other specialized packages.',
      ],
    },
    {
      question: 'Agent Image Pricing',
      answer: [
        'Our pricing is competitive and you can request a FREE consultation before signing up for our packages. Website add-ons, pay-per-click campaigns, subscription fees, and upfront costs will also be clearly laid out for you.',
        "Tap our services and you'll soon be leaving your own Agent Image review!",
      ],
    },
  ]

  return (
    <LayoutInnerPage
      wrapperClassName={`ip-client-testimonials ${scroll ? 'onscoll' : ''}`}
    >
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner breadcrumbs={page?.seo?.breadcrumbs} />
      <ReviewsTrustedByTheBest />
      <HomeReviews title="Video Testimonials" />
      <ReviewsServiceSetsUsApart
        title="Rave Reviews"
        titleClassName="heading-2"
        subtitle="We deliver standout work for the most outstanding agents in the business."
        subtitleClassName="subtitle-1"
        displayStars={false}
      />
      <ReviewsFiveStarWithSlider />
      <ReviewsClientTestimonials />
      <Faqs
        wrapperClassName={cx.faqs}
        bgImg={FaqsBackground}
        heading="Getting to know our services"
        items={faqsData}
      />
      <LeadContactForm
        version="LCF08"
        modelOrBackground="CutOutChadia"
        title="Ready To Build a <br />Website You Love? "
        subtitle="Schedule a free consultation <br />so we can get started."
        submitLabel="Schedule a consultation"
        leadName={`Consultation: ${page.title}`}
        layout="B"
      />
    </LayoutInnerPage>
  )
}

export default ClientTestimonialsTemplate

export const ClientTestimonialsPageQuery = graphql`
  query ClientTestimonialsPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
    }
  }
`
