import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

// Dummy Data
import { sectionData } from '../../../../data/ClientTestimonials'

// CSS
import * as cx from './ClientTestimonials.module.scss'

import SvgStar from '@src/svg/star.inline'

const ReviewsClientTestimonials = () => {
  const [show, setShow] = useState(false)
  const [maxHeight, setMaxHeight] = useState(800)
  const [height, setHeight] = useState(800)

  const contentSpace = useRef(null)

  const toggleAccordion = (e) => {
    const contentHeight = contentSpace.current.scrollHeight
    const newHeight = height + getNumberFromPercentage(35, contentHeight)

    setMaxHeight(contentHeight)
    setHeight(newHeight)

    if (newHeight >= contentHeight) {
      setShow(true)
    }
  }

  const getNumberFromPercentage = (percentage, number) => {
    return (percentage / 100) * number
  }

  const stars = (
    <div className={cx.stars}>
      {[...Array(5)].map((_, key) => (
        <SvgStar key={key} />
      ))}
    </div>
  )

  return (
    <section className={cx.section}>
      <div className={`container ${cx.contain}`}>
        <div className={cx.heading}>
          <h2 className="heading-2">5,954 Glowing Reviews</h2>
          <p className="subtitle-1">
            Our clients agree: Agent Image is the best real estate website
            developer.
          </p>
        </div>
        <div
          className={`${cx.content} ${show === false ? cx.shadow : ''}`}
          style={{ height: `${height}px`, maxHeight: `${maxHeight}px` }}
        >
          <div ref={contentSpace} className={cx.items}>
            {sectionData.map((item, index) => {
              return (
                <div key={index} className={cx.item}>
                  {stars}
                  <p className="default-body large">{item.testimonial}</p>
                  <div>
                    <h3 className="subtitle-3">{item.name}</h3>
                    <a
                      href={`http://${item.domain}`}
                      target="_blank"
                      className="menu-link"
                      rel="noreferrer"
                    >
                      {item.domain}
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {show === false && (
          <div className={cx.more}>
            <button
              className="primary-button large alt"
              onClick={toggleAccordion}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

ReviewsClientTestimonials.propTypes = {}

export default ReviewsClientTestimonials
