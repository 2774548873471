import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ReactPlayer from 'react-player/lazy'

import SvgSpinner from '@svg/spinner.inline'
import SvgClose from '@svg/close.inline'
import * as cx from './Video.module.scss'

const ModalVideo = ({ url, childFunc }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (childFunc) {
      childFunc.current = { openModal, closeModal }
    }
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="modal" onClose={closeModal}>
        <div className="modal-container">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="modal-overlay" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="modal-tricker" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="modal-content">
              <button className={cx['close']} onClick={closeModal}>
                <SvgClose />
              </button>

              <div className={cx.playerWrapper}>
                <div className={cx.spinner}>
                  <SvgSpinner />
                </div>
                <ReactPlayer
                  className={cx.player}
                  url={url}
                  controls={true}
                  playsinline={true}
                  width="100%"
                  height="100%"
                />
                {/* style="position:absolute;top:0;left:0;width:100%;height:100%;" */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ModalVideo
