import React from 'react'

const SvgPlay = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 142 141"
      style={{ enableBackground: 'new 0 0 142 141' }}
      className="fill-current"
      fill="none"
    >
      <path
        d="M57.2862 46.0695L99.5786 70.487L57.2862 94.9045V46.0695Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.874 70.4873C141.874 109.416 110.316 140.975 71.3867 140.975C32.4577 140.975 0.899414 109.416 0.899414 70.4873C0.899414 31.5583 32.4577 0 71.3867 0C110.316 0 141.874 31.5583 141.874 70.4873ZM137.874 70.4873C137.874 107.207 108.107 136.975 71.3867 136.975C34.6668 136.975 4.89941 107.207 4.89941 70.4873C4.89941 33.7674 34.6668 4 71.3867 4C108.107 4 137.874 33.7674 137.874 70.4873Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgPlay
