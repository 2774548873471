// extracted by mini-css-extract-plugin
export var account = "FiveStarWithSlider-module--account--ca628";
export var client = "FiveStarWithSlider-module--client--88024";
export var content = "FiveStarWithSlider-module--content--d0d54";
export var device = "FiveStarWithSlider-module--device--f050f";
export var heading = "FiveStarWithSlider-module--heading--f06e5";
export var headshot = "FiveStarWithSlider-module--headshot--98279";
export var logo = "FiveStarWithSlider-module--logo--0ef7e";
export var slider = "FiveStarWithSlider-module--slider--02939";
export var sliderContent = "FiveStarWithSlider-module--slider-content--b1262";
export var sliderHeading = "FiveStarWithSlider-module--slider-heading--15bce";
export var tablet = "FiveStarWithSlider-module--tablet--771ba";
export var testimonial = "FiveStarWithSlider-module--testimonial--10f32";
export var wrapper = "FiveStarWithSlider-module--wrapper--4244e";