import React from 'react'
import PropTypes from 'prop-types'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useClientTestimonialsMetadata } from '@hooks/client-testimonials'
import parse from 'html-react-parser'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import ProductGalleryPhoneTabletAlt from '@components/global/devices/ProductGalleryPhoneTabletAlt'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './ClientTestimonialsBanner.module.scss'

import ImageBackground from '/content/assets/our-work/client-testimonials/banner-bg.jpg'
import LaptopPratt from '/content/assets/our-work/client-testimonials/laptop-pratt.jpg'
import MobilePratt from '/content/assets/our-work/client-testimonials/phone-pratt.jpg'
import LaptopKofi from '/content/assets/our-work/client-testimonials/laptop-kofi.jpg'
import MobileKofi from '/content/assets/our-work/client-testimonials/phone-kofi.jpg'
import LaptopAltman from '/content/assets/our-work/client-testimonials/laptop-altman.jpg'
import MobileAltman from '/content/assets/our-work/client-testimonials/phone-altman.jpg'

const Banner = ({ breadcrumbs }) => {
  const query = useClientTestimonialsMetadata()
  const items = [
    {
      name: 'Brandi Pratt',
      domain: 'theprattgroup.com',
      url: 'https://theprattgroup.com',
      testimonial:
        '"Love Agent Image! <br />Super happy with my website, always receive quick and outstanding support. Highly recommend them!"',
      laptop: LaptopPratt,
      mobile: MobilePratt,
      logo: getImage(query.LogoPratt),
      headshot: getImage(query.HeadshotPratt),
    },
    {
      name: 'Kofi Nartey',
      domain: 'thenarteygroup.com',
      url: 'https://thenarteygroup.com',
      testimonial:
        'Agent Image has helped us capture our brand in an impressive and engaging way.',
      laptop: LaptopKofi,
      mobile: MobileKofi,
      logo: getImage(query.LogoKofi),
      headshot: getImage(query.HeadshotKofi),
    },
    {
      name: 'The Altman Brothers',
      domain: 'thealtmanbrothers.com',
      url: 'https://thealtmanbrothers.com',
      testimonial:
        'We only work with the best in everything we do, so when it comes to your website, Agent Image is the only choice.',
      laptop: LaptopAltman,
      mobile: MobileAltman,
      logo: getImage(query.LogoAltman),
      headshot: getImage(query.HeadshotAltman),
    },
  ]

  return (
    <section
      className={cx.section}
      style={{
        backgroundImage: `url(${ImageBackground})`,
      }}
    >
      <div className={cx.wrapper}>
        <Breadcrumbs wrapperClassName={cx.breadcrumbs} data={breadcrumbs} />

        <div className={cx.heading}>
          <h1 className="heading-2">Client Testimonials</h1>
          <p className="default-body large">
            Agent Image has been delighting the most discerning clients since
            1999.
          </p>
        </div>

        <Splide
          hasTrack={false}
          tag="div"
          options={{
            type: 'loop',
            arrows: false,
            pagination: true,
            perPage: 1,
            autoHeight: false,
            lazyLoad: 'sequential',
            autoplay: false,
            interval: 5000,
          }}
          className={cx.sliders}
        >
          <SplideTrack>
            {items.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <div className={cx.slider}>
                    <div className={cx.left}>
                      <ProductGalleryPhoneTabletAlt
                        wrapperClass={cx.device}
                        laptopSrc={item.laptop}
                        mobileSrc={item.mobile}
                      />
                      <ElementImageFile
                        src={item.headshot}
                        alt={item.name}
                        lazy={false}
                        className={cx.headshot}
                      />
                    </div>
                    <div className={cx.right}>
                      <div className={cx.logo}>
                        <ElementImageFile
                          src={item.logo}
                          alt={item.name}
                          lazy={false}
                        />
                      </div>
                      <p className="subtitle-9">{parse(item.testimonial)}</p>
                      <p className="subtitle-3">{item.name}</p>
                      <a
                        href={item.url}
                        target="_blank"
                        className="menu-link"
                        rel="noreferrer"
                      >
                        {item.domain}
                      </a>
                    </div>
                  </div>
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>
      </div>
    </section>
  )
}

Banner.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
}

export default Banner
