import { useStaticQuery, graphql } from 'gatsby'

export const useClientTestimonialsMetadata = () => {
  const query = useStaticQuery(graphql`
    query ClientTestimonialsMetaData {
      LogoPratt: file(
        relativePath: { eq: "our-work/client-testimonials/logo-pratt.png" }
      ) {
        id
        ...ClientTestimonialsImageQuality
      }
      HeadshotPratt: file(
        relativePath: { eq: "our-work/client-testimonials/headshot-pratt.png" }
      ) {
        id
        ...ClientTestimonialsImageQuality
      }
      LogoKofi: file(
        relativePath: { eq: "our-work/client-testimonials/logo-kofi.png" }
      ) {
        id
        ...ClientTestimonialsImageQuality
      }
      HeadshotKofi: file(
        relativePath: { eq: "our-work/client-testimonials/headshot-kofi.png" }
      ) {
        id
        ...ClientTestimonialsImageQuality
      }
      LogoAltman: file(
        relativePath: { eq: "our-work/client-testimonials/logo-altman.png" }
      ) {
        id
        ...ClientTestimonialsImageQuality
      }
      HeadshotAltman: file(
        relativePath: { eq: "our-work/client-testimonials/headshot-altman.png" }
      ) {
        id
        ...ClientTestimonialsImageQuality
      }
    }

    fragment ClientTestimonialsImageQuality on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}
