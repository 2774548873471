import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import SvgPlay from '@svg/play.inline'
import * as cx from './PlayButton.module.scss'

const PlayButton = ({ wrapperClassName, onClick }) => {
  return (
    <button
      className={clsx([cx.wrapper], wrapperClassName)}
      type="button"
      onClick={onClick}
    >
      <SvgPlay />
    </button>
  )
}

PlayButton.propTypes = {
  wrapperClassName: PropTypes.string,
  onClick: PropTypes.func,
}

export default PlayButton
