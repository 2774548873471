import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useHomePageMetadata } from '@hooks/home-page-metadata'
import { wpautop } from '@utils/wpautop'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import ModalVideo from '@components/global/modal/Video'
import PlayButton from '@components/global/buttons/PlayButton'
import clsx from 'clsx'
import * as cx from './Reviews.module.scss'
import * as cxHome from '../Home.module.scss'

const HomeReviews = ({
  title = '',
  titleClassName = 'heading-2',
  description = 'Over 20 years of expertise in shaping the industry. Five-star results and thousands of reviews.<br/> We&apos;ll let our clients do the talking.',
  descriptionClassName = 'subtitle-5',
}) => {
  const data = useHomePageMetadata().wp.Testimonials

  const modalVideoFunc = useRef()
  const [activeItem, setActiveItem] = useState(data.items[0].id)
  const [videoUrl, setVideoUrl] = useState(data.items[0].video_url)

  const accordion = (item, url) => {
    if (item === activeItem) {
      modalVideoFunc.current.openModal()
    } else {
      setActiveItem(item)
      setVideoUrl(url)
    }
  }

  return (
    <section id="reviews" className={`${cxHome.section} ${cx.section}`}>
      <ModalVideo childFunc={modalVideoFunc} url={videoUrl} />

      <div className={`${cxHome.heading} ${cx.heading}`}>
        <h2>
          <span className={titleClassName}>
            {title !== '' ? title : parse(data.title)}
          </span>
          <span className="subtitle-1">{parse(data.subtitle)}</span>
        </h2>
        <p className={descriptionClassName}>{parse(description)}</p>
      </div>

      <div className={cx.container}>
        <div className={cx.items}>
          {data.items.map((item, index) => {
            return (
              <div
                key={item.id}
                className={`${cx.item} ${
                  activeItem === item.id ? cx.active : ''
                }`}
                role="button"
                onClick={() => {
                  accordion(item.id, item.video_url)
                }}
              >
                <div className={cx.content}>
                  <ElementImage
                    src={item?.image?.url}
                    width={585}
                    height={780}
                    alt={item.image.alt}
                    srcSet={item?.image?.srcset}
                    sizes={item?.image?.sizes}
                    splideLazyload={false}
                    className={cx.agent}
                  />

                  <div
                    className={`${cx.description} ${
                      activeItem === item.id ? cx.descriptionActive : ''
                    }`}
                  >
                    <div className={`subtitle-5 ${cx.testimonial}`}>
                      “{item.client_testimonial}”
                    </div>
                    <div className={cx.title}>
                      <div className={cx.name}>
                        <h3 className="subtitle-4">{item.client_name}</h3>
                        <a
                          href={item.client_url}
                          target="_blank"
                          className="menu-link"
                          rel="noreferrer"
                        >
                          {item.client_domain.replace('/', '')}
                        </a>
                      </div>
                      <div className={cx.titleLogo}>
                        <ElementImage
                          src={item.logo.url}
                          width={item.logo.width}
                          height={item.logo.height}
                          alt={item.logo.alt}
                          srcSet={item.logo.srcset}
                          sizes={item.logo.sizes}
                        />
                      </div>
                    </div>
                  </div>

                  <PlayButton
                    wrapperClassName={clsx([cx.play], {
                      [cx.playActive]: activeItem === item.id,
                    })}
                  />

                  <div
                    className={`${cx.logo} ${
                      activeItem === item.id ? cx.logoInactive : ''
                    }`}
                  >
                    <ElementImage
                      src={item.logo.url}
                      width={item.logo.width}
                      height={item.logo.height}
                      alt={item.logo.alt}
                      srcSet={item.logo.srcset}
                      sizes={item.logo.sizes}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={cx.button}>
        <Link to={data.button_url} className="primary-button large">
          {data.button_text}
        </Link>
      </div>
    </section>
  )
}

HomeReviews.propTypes = {
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
}

export default HomeReviews
