import React from 'react'
import PropTypes from 'prop-types'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'

import SpliderButtons from '@components/global/buttons/SpliderButtons'
import DeviceTablet from '@components/global/devices/Tablet'
import ElementImage from '@components/global/element/Image'

// CSS
import * as cx from './FiveStarWithSlider.module.scss'

import ImageBackground from '/content/assets/components/five-star-with-slider/background.jpg'
import TabletKofi from '/content/assets/our-work/client-testimonials/laptop-kofi.jpg'
import HeadshotKofi from '/content/assets/components/service-sets-apart/kofi.png'
import LogoKofi from '/content/assets/real-estate-websites/imagine-studio/spotlight-3-logo.png'

const ReviewsFiveStarWithSlider = () => {
  return (
    <section className={cx.wrapper}>
      <div className={`container ${cx.heading}`}>
        <h2 className="heading-2">5-Star Experience</h2>
        <h3 className="subtitle-1">
          Get the white-glove service you give to your own clients
        </h3>
        <p className="subtitle-5">
          True 24/7 Customer Support <span>|</span> Dedicated Project Managers{' '}
          <span>|</span> In-Depth Design Consultations
        </p>
      </div>

      <div
        className={cx.content}
        style={{ backgroundImage: `url(${ImageBackground})` }}
      >
        <div className={`${cx.sliderContent}`}>
          <h2 className={`subtitle-1 ${cx.sliderHeading}`}>
            What our clients are saying
          </h2>

          <Splide
            hasTrack={false}
            tag="div"
            options={{
              type: 'loop',
              isNavigation: true,
              arrows: 2,
              arrowPath: false,
              pagination: false,
              perPage: 1,
              perMove: 1,
              autoHeight: false,
              lazyLoad: 'sequential',
            }}
          >
            <SplideTrack>
              <SplideSlide>
                <div className={cx.slider}>
                  <div className={cx.testimonial}>
                    <p className="subtitle-5">
                      “It was an awesome experience working with Chamille. She
                      helped us design a beautiful website. She showed superb
                      customer service, listening to our goals, and helping us
                      achieve them, and most importantly was very patient every
                      step of the way and was always very responsive no matter
                      what question we had or change, even on the weekends.”
                    </p>
                    <div className={cx.client}>
                      <div className={cx.headshot}>
                        <canvas
                          width={60}
                          height={60}
                          className="rounded-full"
                          style={{ backgroundImage: `url(${HeadshotKofi})` }}
                        ></canvas>
                      </div>
                      <div className={cx.account}>
                        <h3 className="subtitle-3">Kofi Nartey</h3>
                        <a
                          href={`http://goblred.com`}
                          target="_blank"
                          className="menu-link"
                          rel="noreferrer"
                        >
                          goblred.com
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={cx.device}>
                    <DeviceTablet
                      imageSrc={TabletKofi}
                      className={`${cx.tablet}`}
                    />
                    <ElementImage
                      src={LogoKofi}
                      width={315}
                      height={40}
                      alt="Kofi Nartey"
                      className={`${cx.logo}`}
                    />
                  </div>
                </div>
              </SplideSlide>
            </SplideTrack>
            <SpliderButtons withText={true} />
          </Splide>
        </div>
      </div>
    </section>
  )
}

ReviewsFiveStarWithSlider.propTypes = {}

export default ReviewsFiveStarWithSlider
