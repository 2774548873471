// extracted by mini-css-extract-plugin
export var breadcrumbs = "ClientTestimonialsBanner-module--breadcrumbs--d39f9";
export var device = "ClientTestimonialsBanner-module--device--400ae";
export var heading = "ClientTestimonialsBanner-module--heading--531d2";
export var headshot = "ClientTestimonialsBanner-module--headshot--2f9e6";
export var left = "ClientTestimonialsBanner-module--left--dac10";
export var logo = "ClientTestimonialsBanner-module--logo--339e5";
export var right = "ClientTestimonialsBanner-module--right--4d084";
export var section = "ClientTestimonialsBanner-module--section--d62b2";
export var slider = "ClientTestimonialsBanner-module--slider--024d1";
export var sliders = "ClientTestimonialsBanner-module--sliders--44c6c";
export var wrapper = "ClientTestimonialsBanner-module--wrapper--34dab";