export const sectionData = [
  {
    name: 'Mike G.',
    domain: 'website.com',
    testimonial:
      'It was an awesome experience working with Chamille. She helped us design a Beautiful Website. She showed superb customer service, listening to our goals, and helping us achieve them & most important was very patient every step of the way and was always very responsive no matter what quesion we had or change, even on the weekends. She was very knowledgeable in website development and able to direct us through the design process from start to finish. Chamille went above and beyond what I had expected and I am...',
  },
  {
    name: 'Tracey R.',
    domain: 'website.com',
    testimonial:
      "I have been working with Connie for years and I can always count on her to fix or modify anything on my website. She's a quick responder and even quicker problem solver. I'm sure there are other website services out there, but I have no interest in venturing out. 5 Stars to Agent Image.",
  },
  {
    name: 'Ryan S.',
    domain: 'website.com',
    testimonial:
      'I recently had Agent Image redesign my website, and they did an amazing job! Right out of the gate, they focused on SEO, a custom IDX, lead generation - while keeping a luxury design in mind. It’s not only been an amazing experience but I feel as though it’s really set me apart from my competitors. I love my website.',
  },
  {
    name: 'Kristi L.',
    domain: 'website.com',
    testimonial:
      'Lea is amazing! And the team at Agent Image is always very responsive and helpful! I wouldn’t dream of having another company manage my professional website! Awesome team and always helpful!',
  },
  {
    name: 'Catherine M.',
    domain: 'website.com',
    testimonial:
      'Joy was very professional, friendly and knowledgeable! She was very understanding and resolution oriented. A gentle person indeed. Her customer service skills are above and beyond! Keep it up!!!! 😊',
  },
  {
    name: 'Robyn B.',
    domain: 'website.com',
    testimonial: 'Thanks for the prompt attention.',
  },
  {
    name: 'Georgina Jacobson',
    domain: 'website.com',
    testimonial: 'Great proactive service, very responsive!',
  },
  {
    name: 'Georgina Jacobson',
    domain: 'website.com',
    testimonial:
      'Joy Sy has been an absolute pleasure to work with recently. Our SSL certificate was up for renewal and we ran into some issues (on my end) but Joy stayed on top of this and was in constant communication for the last several weeks until we got this done!',
  },
  {
    name: 'Marzetta D.',
    domain: 'website.com',
    testimonial:
      'I recently had Agent Image redesign my website, and they did an amazing job! Right out of the gate, they focused on SEO, a custom IDX, lead generation - while keeping a luxury design in mind. It’s not only been an amazing experience but I feel as though it’s really set me apart from my competitors. I love my website.',
  },
  {
    name: 'Mike G.',
    domain: 'website.com',
    testimonial:
      'It was an awesome experience working with Chamille. She helped us design a Beautiful Website. She showed superb customer service, listening to our goals, and helping us achieve them & most important was very patient every step of the way and was always very responsive no matter what quesion we had or change, even on the weekends. She was very knowledgeable in website development and able to direct us through the design process from start to finish. Chamille went above and beyond what I had expected and I am...',
  },
  {
    name: 'Tracey R.',
    domain: 'website.com',
    testimonial:
      "I have been working with Connie for years and I can always count on her to fix or modify anything on my website. She's a quick responder and even quicker problem solver. I'm sure there are other website services out there, but I have no interest in venturing out. 5 Stars to Agent Image.",
  },
  {
    name: 'Ryan S.',
    domain: 'website.com',
    testimonial:
      'I recently had Agent Image redesign my website, and they did an amazing job! Right out of the gate, they focused on SEO, a custom IDX, lead generation - while keeping a luxury design in mind. It’s not only been an amazing experience but I feel as though it’s really set me apart from my competitors. I love my website.',
  },
  {
    name: 'Kristi L.',
    domain: 'website.com',
    testimonial:
      'Lea is amazing! And the team at Agent Image is always very responsive and helpful! I wouldn’t dream of having another company manage my professional website! Awesome team and always helpful!',
  },
  {
    name: 'Catherine M.',
    domain: 'website.com',
    testimonial:
      'Joy was very professional, friendly and knowledgeable! She was very understanding and resolution oriented. A gentle person indeed. Her customer service skills are above and beyond! Keep it up!!!! 😊',
  },
  {
    name: 'Robyn B.',
    domain: 'website.com',
    testimonial: 'Thanks for the prompt attention.',
  },
  {
    name: 'Georgina Jacobson',
    domain: 'website.com',
    testimonial: 'Great proactive service, very responsive!',
  },
  {
    name: 'Georgina Jacobson',
    domain: 'website.com',
    testimonial:
      'Joy Sy has been an absolute pleasure to work with recently. Our SSL certificate was up for renewal and we ran into some issues (on my end) but Joy stayed on top of this and was in constant communication for the last several weeks until we got this done!',
  },
  {
    name: 'Marzetta D.',
    domain: 'website.com',
    testimonial:
      'I recently had Agent Image redesign my website, and they did an amazing job! Right out of the gate, they focused on SEO, a custom IDX, lead generation - while keeping a luxury design in mind. It’s not only been an amazing experience but I feel as though it’s really set me apart from my competitors. I love my website.',
  },
]
