// extracted by mini-css-extract-plugin
export var active = "Reviews-module--active--8a200";
export var agent = "Reviews-module--agent--b40cb";
export var button = "Reviews-module--button--f5447";
export var container = "Reviews-module--container--a561b";
export var content = "Reviews-module--content--0056a";
export var description = "Reviews-module--description--47525";
export var descriptionActive = "Reviews-module--description-active--ebd15";
export var heading = "Reviews-module--heading--52c36";
export var item = "Reviews-module--item--9f1cf";
export var items = "Reviews-module--items--28c5f";
export var logo = "Reviews-module--logo--1e7d2";
export var logoInactive = "Reviews-module--logo-inactive--23e08";
export var name = "Reviews-module--name--c15ae";
export var play = "Reviews-module--play--d912d";
export var playActive = "Reviews-module--play-active--76883";
export var section = "Reviews-module--section--adeec";
export var testimonial = "Reviews-module--testimonial--0408b";
export var title = "Reviews-module--title--10ce8";
export var titleLogo = "Reviews-module--title-logo--67455";